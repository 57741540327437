
<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
export default {
    page: {
        title: "Rapport des Salles",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {
      Multiselect,
      Layout, PageHeader,
      Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      // DatasetSearch,
      DatasetShow,
    },
    data(){
        return {
            title:"Rapport des Salles",
            items:[],
            cols: [
                    {
                        name: 'Heure',
                        field: 'ref',
                        sort: ''
                    },
                    {
                        name: 'Service',
                        field: 'lifebase',
                        sort: ''
                    },
                    {
                        name: 'Base de vie',
                        field: 'contract',
                        sort: ''
                    },
                    {
                        name: 'Salle',
                        field: 'supplier',
                        sort: ''
                    },
                    {
                        name: 'Matricule',
                        field: 'mat',
                        sort: ''
                    },
                    {
                        name: 'Employée',
                        field: 'emp',
                        sort: ''
                    },
                    {
                        name: 'Structure',
                        field: 'str',
                        sort: ''
                    }
                ],
                checkes : [],
                camps : [],
                salles : [],
                date:"",
                camp:"",
                salle:"",
                cpt_anal:"",
                matricule:"",
                selectedIndex:-1,
                breakfest:0,
                lunch:0,
                diner:0
        }
    },
    mounted() {
        this.getList();
        this.getDataSearch();
    },
    methods:{
        getDataSearch(){
            this.$http.get('/system/checker/paramsSearch')
            .then(response => {
                this.camps = response.data[0];
                this.salles = response.data[1];
            })
            .catch(error => console.log(error))
        },
        getList(){
            this.$http.get('/system/checker/list')
            .then(response => {
                this.checkes = response.data.checks;
                this.breakfest = response.data.break;
                this.lunch = response.data.lunch;
                this.diner = response.data.diner;
            })
            .catch(error => console.log(error))
        },
        getSearch(){
            this.checkes = [];
            this.$http.post('/system/checker/search',{
                date:this.date,
                bdv:this.camp ? this.camp.name : null,
                salle:this.salle ? this.salle.name : null,
                cpt_anal:this.cpt_anal,
                matricule:this.matricule,
            })
            .then(response => {
                this.checkes = response.data.checks;
                this.breakfest = response.data.break;
                this.lunch = response.data.lunch;
                this.diner = response.data.diner;
            })
            .catch(error => console.log(error))
        },
        selectRow(row,index){
            if(this.selectedIndex == index) {this.selectedIndex = -1;}
            else {
                this.selectedIndex = index;
            }
        },
    }
}
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col">
                <input type="date" v-model="date" class="form-control">
            </div>
            <div class="col">
                <multiselect
                  :searchable="true"
                  v-model="camp"
                  track-by="name"
                  label="name"
                  :options="camps"
                  placeholder="Selectionner une base"
                  :select-label="''"
                  :allow-empty="true"
                >
                  <template slot="singleCamp" slot-scope="{ camp }">{{
                    camp.name
                  }}</template>
                </multiselect>
            </div>
            <div class="col">
                <multiselect
                  :searchable="true"
                  v-model="salle"
                  track-by="name"
                  label="name"
                  :options="salles"
                  placeholder="Selectionner une salle"
                  :select-label="''"
                  :allow-empty="true"
                >
                  <template slot="singleCamp" slot-scope="{ camp }">{{
                    camp.name
                  }}</template>
                </multiselect>
            </div>
            <div class="col">
                <input type="text" v-model="cpt_anal" class="form-control" placeholder="Compte Analytique">
            </div>
            <div class="col">
                <input type="text" v-model="matricule" class="form-control" placeholder="Matricule">
            </div>
            <div class="col-sm-1">
                <button class="btn btn-primary" @click="getSearch()">Rechercher</button>
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col m-3">
                <b-card>
                    <div class="row">
                        <div class="col">
                            <span class="h4">Petit Déjeuner</span>
                        </div>
                    </div>
                    <div class="row  mt-2">
                        <div class="col">
                            <span class="h5"> {{ breakfest }} </span>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col m-3">
                <b-card>
                    <div class="row">
                        <div class="col">
                            <span class="h4">Déjeuner</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <span class="h5"> {{ lunch }} </span>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col m-3">
                <b-card>
                    <div class="row">
                        <div class="col">
                            <span class="h4">Dîner</span>
                        </div>
                    </div>
                    <div class="row  mt-2">
                        <div class="col">
                            <span class="h5"> {{ diner }} </span>
                        </div>
                    </div>
                </b-card>
            </div>
            
        </div>
        <b-card>
            <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <dataset
                  v-slot="{ ds }"
                  :ds-data="checkes"
                >
                  
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" class="text-center" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td class="text-center">{{ row.heure }}</td>
                                <td class="text-center">{{ row.service }}</td>
                                <td class="text-center">{{ row.zone }}</td>
                                <td class="text-center">{{ row.salle }}</td>
                                <td class="text-center">{{ row.matricule }}</td>
                                <td class="text-center">{{ row.nom }} {{ row.prénom }}</td>
                                <td class="text-center">{{ row.structure }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-show :ds-show-entries="5" />
                    <dataset-pager />
                  </div>
                </dataset>
              </div>
            </b-card-body>
        </b-card>
    </Layout>
</template>